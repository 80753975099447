import logo from './../../../assets/img/logo.svg';

class HeaderView {
   #userIsLoggedIn;
   #headerHtml;

   renderHeader(userIsLoggedIn) {
      this.#userIsLoggedIn = userIsLoggedIn;

      this.#generateHeaderHtml();
      this.#displayHeaderHtml();
   }

   #generateHeaderHtml() {
      this.#headerHtml = `
   <a class="logo" href="./index.html">
      <object data="${logo}"></object>
      <h3>Kiku</h3>
   </a>
  <nav>
    <ul>
      <li><a href="./pricing.html">Pricing</a></li>
      <li><a href="./public.html">Public Decks</a></li>
      <li><a href="/contact.html">Contact</a></li>
    </ul>

      <ul class="mobile-button">
         ${
            this.#userIsLoggedIn
               ? `
            <li><a class="btn log-out" href="./index.html">Log out</a></li>
            <li><a class="btn rainbow-button" href="./app.html#home">My dashboard</a></li>
         `
               : `
            <li><a class="btn" href="./login.html">Log in</a></li>
            <li><a class="btn rainbow-button" href="./signup.html">Sign up</a></li>
         `
         }
      </ul>

   <div class="exit-icon">
      <svg class="close" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" fill="#000000" stroke="#000000" stroke-width="34.816">
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
               <path fill="#000000" d="M764.288 214.592 512 466.88 259.712 214.592a31.936 31.936 0 0 0-45.12 45.12L466.752 512 214.528 764.224a31.936 31.936 0 1 0 45.12 45.184L512 557.184l252.288 252.288a31.936 31.936 0 0 0 45.12-45.12L557.12 512.064l252.288-252.352a31.936 31.936 0 1 0-45.12-45.184z"></path>
            </g>
         </svg>
      </div>
  </nav>
  <ul>
    ${
       this.#userIsLoggedIn
          ? `
      <li class="logout-desktop"><a class="btn log-out" href="./index.html">Log out</a></li>
      <li><a class="btn rainbow-button" href="./app.html#home">My dashboard</a></li>
    `
          : `
      <li><a class="btn" href="./login.html">Log in</a></li>
      <li><a class="btn rainbow-button" href="./signup.html">Sign up</a></li>
    `
    }
  </ul>
  <div class="burger-icon">
      <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 18L20 18" stroke="#000000" stroke-width="1.2" stroke-linecap="round"></path> <path d="M4 12L20 12" stroke="#000000" stroke-width="1.2" stroke-linecap="round"></path> <path d="M4 6L20 6" stroke="#000000" stroke-width="1.2" stroke-linecap="round"></path> </g></svg>
  </div>`;
   }

   #displayHeaderHtml() {
      const header = document.querySelector('header');

      header.innerHTML = this.#headerHtml;
   }

   createLogoutEventListener = handler => {
      const logOutBtn = document.querySelectorAll('.log-out');

      logOutBtn.forEach(btn => btn.addEventListener('click', handler));
   };
}

export default new HeaderView();
