import { API_URL } from '../../config';
import { postJSON, postJSONMultiform } from './../../helpers/requests';

class IndexModel {
   async createCards(type, data) {
      const generateCardsUrl = `${API_URL}/cards-generate/${type}/trial`;

      let createCards;

      if (type === 'pdf')
         createCards = await postJSONMultiform(generateCardsUrl, data);
      else createCards = await postJSON(generateCardsUrl, data);

      return createCards;
   }
}

export default new IndexModel();
