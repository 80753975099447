const checkFormForErrors = inputs => {
   let errorsFound;

   for (const input of inputs) {
      const error = input.closest('.input-container').querySelector('.error');

      if (!input.checkValidity()) {
         input.classList.add('error-input');
         error.classList.remove('hiddenErr');
         error.querySelector('p').textContent = input.validationMessage;

         errorsFound = true;
      } else {
         input.classList.remove('error-input');
         error.classList.add('hiddenErr');
         error.querySelector('p').textContent = '';
      }
   }

   return errorsFound;
};

const grabFormValues = form => {
   const formData = new FormData(form);
   const formDataObj = Object.fromEntries(formData);

   return formDataObj;
};

const clearFormInputs = form => {
   form.querySelectorAll('input, textarea').forEach(entry => {
      entry.value = '';
      entry.textContent = '';
   });
};

export { checkFormForErrors, grabFormValues, clearFormInputs };
