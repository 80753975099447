import { API_URL } from '../../config';
import { postJSON } from '../../helpers/requests';

class HeaderModel {
   async logUserOut() {
      const logoutURL = `${API_URL}/auth/log-out`;

      const res = await postJSON(logoutURL);

      return res;
   }

   removeUserLocalStorage() {
      localStorage.clear();
   }
}

export default new HeaderModel();
