import PopupView from '../view/PopupView';

class PopupController {
   constructor() {
      this.#registerEventListeners();
   }

   show(title, popupContentEl) {
      PopupView.show(title, popupContentEl);
   }

   close() {
      PopupView.close();
   }

   #registerEventListeners() {
      PopupView.createCloseEventListener(this.close);
   }
}

export default new PopupController();
