import IndexModel from '../model/IndexModel';
import IndexView from '../view/IndexView';
import HeaderController from './HeaderController';
import { checkFormForErrors, grabFormValues } from './../../helpers/form';
import { renderSpinner } from '../../helpers/renderSpinner';
import PopupController from './../../shared/controller/PopupController';
import { showMessage } from '../../helpers/messages';

class IndexController {
   init() {
      HeaderController.init();

      this.#registerEventListeners();

      this.#checkLocalStorageForCards();

      IndexView.startTypingSectionOne();
   }

   #registerEventListeners() {
      IndexView.handleEmailSignup(this.#userSubmittedEmail);
      IndexView.showUploadPopup(this.#showPopup);
      IndexView.popupClickListener(this.#showPopup, this.#createCards);
      // IndexView.handleSectionThreeList();
   }

   #checkLocalStorageForCards() {
      const cards = JSON.parse(window.localStorage.getItem('trial-cards'));

      if (!cards) return;

      IndexView.populateCards(cards);
   }

   #showPopup(title, content) {
      PopupController.show(title, content);
   }

   async #createCards(button, form, formInputs) {
      renderSpinner(button, 'white');

      const errors = checkFormForErrors(formInputs);

      if (errors) {
         button.textContent = 'Generate cards';
         return;
      }

      const formValues = grabFormValues(form);

      try {
         let cards;

         if (form.classList.contains('youtube'))
            cards = await IndexModel.createCards('youtube', formValues);

         if (form.classList.contains('pdf')) {
            const formData = new FormData(form);
            cards = await IndexModel.createCards('pdf', formData);
         }

         if (form.classList.contains('notes'))
            cards = await IndexModel.createCards('text', formValues);

         if (form.classList.contains('web-page'))
            cards = await IndexModel.createCards('website', formValues);

         if (cards.statusCode !== 200) throw new Error(cards.message);

         window.localStorage.setItem(
            'trial-cards',
            JSON.stringify(cards.data.data)
         );

         IndexView.populateCards(cards.data.data);

         PopupController.close();
      } catch (err) {
         console.error(err);
         showMessage('negative', err.message);
      }

      button.textContent = 'Generate cards';
   }

   #userSubmittedEmail(form, btn, input) {
      const errors = checkFormForErrors([input]);

      if (errors) return;

      renderSpinner(btn, 'white');

      const { email } = grabFormValues(form);

      window.location.assign(`/signup.html?email=${email}`);
   }
}

export default new IndexController();
