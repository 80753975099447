import waitInSeconds from '../../helpers/waitInSeconds';

class PopupView {
   #popupEl = document.querySelector('.popup');
   #blurryEl = document.querySelector('.blurry-overlay');
   #allContentInPopup = this.#popupEl.querySelectorAll('.content');
   #popupContentEl;

   async show(title, popupContentEl) {
      this.#allContentInPopup.forEach(content =>
         content.classList.add('hidden')
      );

      this.#popupContentEl = popupContentEl;

      this.#popupEl.querySelector('h3').textContent = title;
      this.#popupContentEl.classList.remove('hidden');
      this.#popupEl.classList.add('active');
      this.#blurryEl.classList.add('active');
   }

   async close() {
      if (!this.#popupEl.classList.contains('active')) return;

      this.#popupEl.classList.remove('active');
      this.#blurryEl.classList.remove('active');

      // Wait 3 seconds before hiding the popup content (so the animation has time to finish)
      await waitInSeconds(0.3);

      this.#popupContentEl.classList.add('hidden');

      this.#popupEl.style.borderLeft = 'none';
   }

   createCloseEventListener(handler) {
      this.#popupEl.querySelector('.close').addEventListener('click', handler);
      this.#blurryEl.addEventListener('click', handler);

      window.addEventListener('keydown', e => {
         if (e.key === 'Escape' && this.#popupEl.classList.contains('active'))
            handler();
      });
   }
}

export default new PopupView();
