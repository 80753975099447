import waitInSeconds from '../../helpers/waitInSeconds';

class IndexView {
   #uploadBtn = document.querySelector('button.upload');
   #popup = document.querySelector('.popup');
   #addCardsForm = this.#popup.querySelector('form.add-card');
   #youtubeForm = this.#popup.querySelector('form.youtube');
   #webForm = this.#popup.querySelector('form.web-page');
   #pdfForm = this.#popup.querySelector('form.pdf');
   #notesForm = this.#popup.querySelector('form.notes');
   #highlightSpan = document.querySelector('section.one span.highlight');
   #wordsArr = JSON.parse(this.#highlightSpan.dataset.words);
   #timingOfLetter = 0.1;
   #index = 0;

   // Handles the section three list (toggle of the images based on the button clicked)
   // handleSectionThreeList() {
   //    const sectionThreeList = document.querySelector('section.three ul');
   //    const sectionThreeListBtns = sectionThreeList.querySelectorAll('button');
   //    const sectionThreeImgs = document.querySelectorAll('section.three img');

   //    sectionThreeList.addEventListener('click', e => {
   //       const clickedBtn = e.target.closest('button');

   //       if (!clickedBtn) return;

   //       sectionThreeListBtns.forEach(btn => btn.classList.remove('active'));

   //       const clickedBtnId = clickedBtn.dataset.id;

   //       sectionThreeImgs.forEach(img => img.classList.remove('active'));

   //       sectionThreeImgs[clickedBtnId]?.classList.add('active');

   //       clickedBtn.classList.add('active');
   //    });
   // }

   async startTypingSectionOne() {
      await waitInSeconds(2);
      this.#alterLetters();
      setInterval(() => this.#alterLetters(), 4 * 1000);
   }

   async #alterLetters() {
      const allLetters = this.#highlightSpan.textContent.split('');

      // Remove (erase) all letters
      for (let i = allLetters.length - 1; i >= 0; i--) {
         // Remove last element from array of letters
         allLetters.pop();

         // Join all of the remaining letters and write it to the DOM
         this.#highlightSpan.innerHTML = allLetters.join('');

         await waitInSeconds(this.#timingOfLetter);
      }

      this.#index++;

      if (this.#index === this.#wordsArr.length) this.#index = 0;

      const currWord = this.#wordsArr[this.#index];

      // Write letters to the screen
      for (let i = 0; i < currWord.length; i++) {
         // allLetters is an empty array after erasing all of the letters, so let's push to it
         allLetters.push(currWord[i]);

         // Write it to the DOM
         this.#highlightSpan.innerHTML = allLetters.join('');

         await waitInSeconds(this.#timingOfLetter);
      }

      // Add a period at the end
      this.#highlightSpan.insertAdjacentHTML(
         'beforeend',
         `<span class="period">.</span>`
      );
   }

   handleEmailSignup(handler) {
      const emailForm = document.querySelector('section.one form');
      const emailFormBtn = emailForm.querySelector('button');
      const emailInput = emailForm.querySelector('input');

      emailFormBtn.addEventListener('click', e => {
         e.preventDefault();

         handler(emailForm, emailFormBtn, emailInput);
      });
   }

   showUploadPopup(showPopup) {
      this.#uploadBtn.addEventListener('click', () => {
         showPopup('Add Cards', this.#addCardsForm);
      });
   }

   popupClickListener(showPopup, createCards) {
      this.#popup.addEventListener('click', e => {
         const button = e.target.closest('button');

         if (!button) return;

         e.preventDefault();

         if (button.classList.contains('go-back-add-cards'))
            return showPopup('Add cards', this.#addCardsForm);

         if (button.classList.contains('youtube'))
            return showPopup('YouTube Video', this.#youtubeForm);

         if (button.classList.contains('web-page'))
            return showPopup('Web URL', this.#webForm);

         if (button.classList.contains('notes'))
            return showPopup('Notes', this.#notesForm);

         if (button.classList.contains('pdf'))
            return showPopup('PDF', this.#pdfForm);

         if (button.classList.contains('create-card')) {
            const form = button.closest('form');
            const allInputs = form.querySelectorAll('input, textarea');
            createCards(button, form, allInputs);
         }
      });
   }

   populateCards(cards) {
      this.#uploadBtn.remove();

      const cardsUl = document.querySelector('section.four ul');
      const cardsGeneratedText = document.querySelector(
         'section.four .cards-generated'
      );
      const leftRightBts = document.querySelector('.left-right-buttons');

      leftRightBts.style.display = 'flex';

      cardsGeneratedText.querySelector('span').textContent = `${
         cards.length === 1 ? '1 card was' : `${cards.length} cards were`
      }`;
      cardsGeneratedText.style.display = 'block';

      cardsUl.innerHTML = '';

      cards.forEach((card, index) => {
         const html = `<li class="${index === 0 ? 'active' : ''}">
                           <div class="front">${card.front}</div>
                           <div class="back">${card.back}</div>
                       </li`;

         cardsUl.insertAdjacentHTML('beforeend', html);
      });

      this.#carouselButtons(cards.length, leftRightBts);
   }

   #carouselButtons(lengthOfCards, leftRightBts) {
      const allCards = [...document.querySelectorAll('section.four ul li')];
      const cardCount = document.querySelector('section.four .card-count');
      let index = 0;

      cardCount.textContent = `1 / ${lengthOfCards}`;

      leftRightBts.addEventListener('click', e => {
         const button = e.target.closest('button');

         if (!button) return;

         allCards.forEach(card => card.classList.remove('active'));

         if (button.classList.contains('right-button')) {
            index++;

            if (index === lengthOfCards) index = 0;
         }

         if (button.classList.contains('left-button')) {
            index--;

            if (index < 0) index = lengthOfCards - 1;
         }

         allCards[index].classList.add('active');

         cardCount.textContent = `${index + 1} / ${lengthOfCards}`;
      });
   }
}

export default new IndexView();
