import { renderSpinner } from '../../helpers/renderSpinner';
import { showMessage, sendMessageToNextPage } from '../../helpers/messages';
import HeaderModel from '../model/HeaderModel';
import HeaderView from '../view/HeaderView';
import userIsLoggedIn from '../../helpers/userIsLoggedIn';
import waitInSeconds from '../../helpers/waitInSeconds';
import BurgerController from '../../shared/controller/BurgerController';

class HeaderController {
   async init(redirect = false) {
      const userLoggedIn = await userIsLoggedIn(true);

      // If the user is logged in and a redirectToDashboard is true, we redirect them
      // And show them the message on the next page
      if (userLoggedIn && redirect) {
         window.location.assign('/app.html#home');
         return;
      }

      // Render the logged in / logged out header
      HeaderView.renderHeader(userLoggedIn);

      // Initialize the burger menu (for mobile)
      BurgerController.init();

      // If the user is logged in, we create the log out btn event listener
      if (userLoggedIn) HeaderView.createLogoutEventListener(this.logOut);
   }

   async logOut(e) {
      e.preventDefault();

      renderSpinner(e.target, 'blue');

      try {
         const res = await HeaderModel.logUserOut();

         // If there was an error logging the user out
         if (res.statusCode !== 200) throw new Error(res.message);

         // If the user was successfully logged out
         // Remove the user's data from local storage
         HeaderModel.removeUserLocalStorage();

         // Wait for 1 second and then reload the page
         await waitInSeconds(1);
         sendMessageToNextPage('positive', 'You have successfully logged out!');
         window.location.reload();
      } catch (err) {
         console.error(err);
         showMessage('negative', err.message);
      }
   }
}

export default new HeaderController();
